import {controller, target} from '@github/catalyst'
import type {PresenceItem} from '../alive-presence'
import {fromEvent} from '../subscription'

@controller
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PresenceAvatars extends HTMLElement {
  @target avatar: HTMLImageElement

  private subscription: ReturnType<typeof fromEvent> | undefined
  private avatarsByUserId = new Map<number, HTMLImageElement>()
  private usersPresent = new Set<number>()

  connectedCallback() {
    // Grab the existing avatar from the stack and hold onto it for cloning
    const avatarContainer = this.avatar.parentElement
    const baseAvatar = this.avatar
    baseAvatar.hidden = true
    this.hidden = false

    if (!avatarContainer) {
      return
    }

    // Remove any existing avatars.  There can be some residual after a PJAX cache load
    // NOTE: we need to convert the HTMLCollection to an array so that it doesn't mutate while we are looping through it
    for (const child of Array.from(avatarContainer.children)) {
      if (child !== baseAvatar) {
        avatarContainer.removeChild(child)
      }
    }

    // register the element as a socket channel, which will cause it to automatically subscribe to events
    this.classList.add('js-socket-channel')

    const addAvatar = (userId: number) => {
      if (this.usersPresent.has(userId)) {
        return
      }

      this.usersPresent.add(userId)
      try {
        const avatar = baseAvatar.cloneNode(true) as HTMLImageElement
        // We will need to fetch the user to get the avatar url and login
        avatar.src = avatar.src.replace(/u\/[0-9]+/, `u/${userId}`)
        // This is using a depracted hovercard endpoint.
        avatar.setAttribute('data-hovercard-url', `/hovercards?user_id=${userId}`)
        avatar.hidden = false
        avatar.removeAttribute('data-target')
        avatarContainer?.appendChild(avatar)
        this.avatarsByUserId.set(userId, avatar)
      } catch (error) {
        this.usersPresent.delete(userId)
      }
    }
    const removeAvatar = (userId: number) => {
      this.usersPresent.delete(userId)
      const avatar = this.avatarsByUserId.get(userId)
      if (avatar) {
        avatarContainer?.removeChild(avatar)
        this.avatarsByUserId.delete(userId)
      }
    }

    // Subscribe to the presence events
    this.subscription = fromEvent(this, 'socket:presence', (event: Event) => {
      // NOTE: This is still a rough POC
      // We're not handling multiple joins by the same user, and we should match records based on presenceId
      const {data: presenceItems} = (event as CustomEvent).detail as {data: PresenceItem[]}

      const originalUserIds = this.avatarsByUserId.keys()
      const newUserIds = new Set<number>()

      for (const {userId} of presenceItems) {
        addAvatar(userId)
        newUserIds.add(userId)
      }

      for (const userId of originalUserIds) {
        if (!newUserIds.has(userId)) {
          removeAvatar(userId)
        }
      }
    })
  }

  disconnectedCallback() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
